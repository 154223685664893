<template>
    <div>
    <div class="mainContainers" @click="goToPackageDetails" >
        <img :src="item.packageBanner" alt="" style="border-top-left-radius: inherit; border-top-right-radius: inherit; height: 150px; object-fit: cover;">
        <div style="width: 100%; padding: 16px; padding-top: 0;">
            <OPDPackageNameAndTestDetails  :showApplicableFor="true" :showBookSlot="true" :showPrice="true" :item="item"/>
            <div class="hospitalNameContainer" v-if="showHospitalInfo" style="margin-top: 16px;">
                <div class="hospitalLogo">
                    <img :src="item.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: contain; border-radius: 8px;"/>
                </div>
                <div class="hospitalName">
                    <div style="font-size: 14px; font-weight: 500; color: #333333;">{{ item.hospitalName }}</div>
                    <div style="font-size: 12px; font-weight: 400; color: #4f4f4f;">{{ item.hospitalAddress }}</div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
import OPDPackageNameAndTestDetails from './OPDPackageNameAndTestDetails.vue';

export default {
  components: { OPDPackageNameAndTestDetails },
//   components: { doctorInfoComponent, ViewDoctorComponent },
    name: 'ViewParticularPackageComp',
    props: ["item", "showHospitalInfo"], 
    data: function() {
        return {
            bookingInfo: {},
            bookingAllowed: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            bookingLoader: false,
            doctorData: null,
            showSelectSlot: false,
            selectedID: -1,
            showAddPatient: false,
            selectedSlotId: -1,
            reviewDialg: false,
            items: ["My Self", "Ankur"],
            currentCustomer: null,
            selectedDateItem: null,
            openCalender: false,
            datePickerModel: null,
            dependentSelected: null,
            showSlot: false,
            slotInfo: [],
            slotLoader: false,
            overlay: false,
            calendarLoader: false,
            allowedDates: [],
            timeInfo: '',
            viewDoctorModel: false,
            cutOffHours: 0,
            calendarDateNumber: null,
            showHospitalInfo: true,
            // dependent_members: []
        };
    },
    async mounted() {
        this.allowedDates = this.getXDaysFromToday(90);
    },
    computed: {
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
    },
    methods: {
        async openDoctorBox() {

            try {
                if (this.doctorData == null) {
                    let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.item.doctorInfo.id}`);
                    this.doctorData = apiResponse.data.doctorDetails;
                }
                this.viewDoctorModel = true;

            } catch (error) {
                console.log(error);
            }
        },
        toggleViewDoctorDialog(value) {
            if(value) {
                this.viewDoctorModel = true;
            } else {
                this.viewDoctorModel = false;
            }
        },
        formatDate(dateString) {
        // Extract year, month, and day from the given string
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Months are zero-based in JavaScript
        const day = dateString.substring(6, 8);

        // Create a new Date object
        const date = new Date(year, month, day);

        // Get the day and month name
        const dayName = date.toLocaleString('en-US', { weekday: 'long' });
        const monthName = date.toLocaleString('en-US', { month: 'long' });

        // Format the date
        const formattedDate = `${day} ${monthName}`;

        return formattedDate;
        },

    openReviewBox() {
        console.dir(this.selectedDateItem);
        console.log(this.selectedID);
        this.bookingInfo = {
            'doctorInfo': {
                doctorName: this.item.doctorInfo.doctorNameEN,
                doctorLogoURL: this.item.doctorInfo.doctorLogo,
                doctorSpecialty: this.item.doctorInfo.doctorSpecialty,
                hospitalLogo: this.hospitalData.hospitalLogo,
                hospitalName: this.hospitalData.name,
                hospitalAddress: this.hospitalData.address,
                slotTime: this.timeInfo,
                slotDate: '24 Aug'                
            }
        }
        this.reviewDialg = true;
        console.dir(this.bookingInfo);
    },
    isDateAllowed(date) {
      console.log(date);
      return this.allowedDates.includes(date);
    },
    getXDaysFromToday(x) {
        const dates = [];
        const today = new Date();
        for (let i = 0; i < x; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            dates.push(`${yyyy}-${mm}-${dd}`);
        }
        return dates;
    },
  calculateDateTime(hours, dateNumber, startTime) {
    console.log(hours, dateNumber, startTime);
    const today = new Date();
    hours = Number(hours);
    today.setHours(today.getHours() + hours);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(today.getDate()).padStart(2, '0');
    const HH = String(today.getHours()).padStart(2, '0');
    const mmFormat = String(today.getMinutes()).padStart(2, '0');
    const dateObject = {
        dateValue: Number(`${yyyy}${mm}${dd}`),
        time: `${HH}:${mmFormat}`
    };
    // Check if dateNumber and startTime are greater than dateObject values
    const inputDateValue = Number(dateNumber);
    const inputTimeValue = Number(startTime.replace(':', ''));
    let allowed = inputDateValue > dateObject.dateValue ||
            (inputDateValue === dateObject.dateValue && inputTimeValue > Number(dateObject.time.replace(':', '')));
            console.log(allowed);
    return allowed;
    },

        async bookSlot() {
            this.bookingLoader = true;
            try {
                let apiResponse = await axios_auth_instance_hba_customer.post('/opd/book', {
                    hospital_ID: this.item.doctorInfo.hospitalRegNo,
                    slot_ID: `${this.item.doctorInfo.id}#${this.selectedSlotId}`,
                    booked_for: this.dependentSelected
                });
                let bookingID = apiResponse.data.bookingID;
                this.$router.push({
                    name: 'hospitalBookingSuccess',
                    params: {
                        bookingID: bookingID,
                    }
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.bookingLoader = false;
            }
        },
        async toggleSlot() {
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            if (this.showSlot === false) {
                try {
                    this.slotLoader = true;
                    let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}`);
                    this.slotInfo = apiResponse.data.slotList;
                    this.cutOffHours = apiResponse.data.cutOffHour;
                
                    this.slotInfo.push({
                            slotID: 'CALENDAR',   
                        }
                    );
                    this.showSlot = true;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.slotLoader = false;
                }
            } else {
                this.showSlot = false;
            }

        },
        callme() {
            this.bookingAllowed = true;
        },
        async getParticularDateData() {
            try {
                this.calendarLoader = true;
                this.overlay = true;
                let splitedDate = this.datePickerModel.split('-');
                let dateInput = splitedDate[0] + splitedDate[1] + splitedDate[2];
                let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}/${dateInput}`);
                if(apiResponse.data.slotList.length == 0) {
                    alert('No slots available');
                    return;
                }
                this.selectedDateItem = {
                    slotID: 'CALENDAR',
                    slotTimes: apiResponse.data.slotList
                }
                this.calendarDateNumber = dateInput;
                this.showSelectSlot = true;
                this.openCalender = false;
            } catch (error) {
                console.log(error);
            } finally {
                this.calendarLoader = false;
            }

        },  
        getClassForDateBox(index, slotItem) {
            // if(slotItem.slotID == 'CALENDAR' && index != this.selectedID) return "dateBox"

            if(slotItem.slotID != 'CALENDAR' && slotItem.slotTimes.length == 0) {
                return "dateBox disabledDateBox"
            }
            if(index == this.selectedID) {
                return "dateBox selectedDateBox"
            } else {
                return "dateBox"
            }
        },
        getClassForSlotBox(index) {
            if(index == this.selectedSlotId) {
                return "slotBox selectedDateBox"
            } else {
                return "slotBox"
            }
        },
        showSelectSlotFn(id, slotItem) {
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            // if(id == 'CALENDAR') return;
            if(id == 'CALENDAR') {
                this.selectedID = id;
                this.selectedDateItem = slotItem;
                this.openCalender = true;
                return;
            }
            if(slotItem.slotTimes.length == 0) return;
            this.selectedID = id;
            this.selectedDateItem = slotItem;
            this.showSelectSlot = true;
        },
        showAddPatientFn(id, item) {
            console.log(this.selectedDateItem);
            if(this.calculateDateTime(this.cutOffHours, this.selectedDateItem.slotID == 'CALENDAR' ? this.calendarDateNumber : this.selectedDateItem.slotID, item.startTime) == false) {
                alert('Booking not allowed');
                return;
            }
            // if any selected before
            this.selectedSlotId = null;
            this.timeInfo = null;
            this.showAddPatient = true;
            this.selectedSlotId = id;
            this.timeInfo = item.timeInfo;

        },
        goToPackageDetails() {
            this.$router.push({
                name: 'OPDPackageDetails',
                params: {
                    'packageID': this.item.id
                }
            })
        },
    },
};

</script>
<style  scoped>
.confirmBookingComponent {
    position: absolute;
    bottom: 10px;
    background: white;
    padding: 14px 16px 12px 16px;
    width: 100%;
    max-width: 425px;
    z-index: 100;
    /* margin: 0 !important; */
}
.mainContainers {
    margin: 0 16px;
    /* padding: 16px; */
    background: white;
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
    z-index: -2122;
}
.headerHeading {
    color: white;
    font-weight: 600;
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.dateBox {
    display: flex;
    padding: 4px 12px 4px 12px;
    border: 1px solid #e0e0e0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}
.selectedDateBox {
    background: #E4F5FF;
    border: 1px solid #48ACEF !important;
}
.disabledDateBox {
    background: #FFF;
    border: 1px solid #f2f2f2 !important;
}
.selectedDateBox .weekDayClass {
    color: #48ACEF !important;
}
.disabledDateBox .weekDayClass {
    color: #e0e0e0 !important;
    font-weight: 800 !important;
}
.slotBox {
    display: flex;
    align-items: center;
    padding: 10px 12px 10px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #828282;
    font-weight: 400;
    width: 100%;
}
.v-input >>> .v-input__slot {
    padding: 0 !important;
}
.v-input__slot >>> .v-text-field {
    display: none;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.viewHospitalDetailsBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.viewDetailsBottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.viewDetailsBody {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}
.bottomBar {
    border-top: 1px solid #e8e8eb;
    display: flex; justify-content: space-between; align-items: center; width: 100%;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px -4px 8px 0px #0000000A;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}  
</style>